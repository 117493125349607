
  const userEditorScriptEntry = { editorReady() {} }
  const editorScriptEntry = userEditorScriptEntry;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  

    var createExperiments = null;
    var createWidgetExperiments = null;
    

  
import { createHttpClient } from '@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp';
    


  import { createOwnerBILogger as biLogger } from '/home/builduser/work/c17fdbb6a863fb49/packages/rentle-secured-checkout-app/target/generated/bi/createBILogger.ts';

  
  import { editorReadyWrapper, onEventWrapper, createFlowAPIFabric, exportsWrapper, getAppManifestWrapper } from '@wix/yoshi-flow-editor/runtime/esm/editorScript.js';

  let editorReady = editorScriptEntry.editorReady;
  const onEvent = onEventWrapper({ onEvent: editorScriptEntry.onEvent });
  const sentryConfig = {
    DSN: 'https://90bc668c4ce447d78966d4dae7a3e685@sentry.wixpress.com/5605',
    id: 'undefined',
    projectName: 'rentle-secured-checkout-app',
    teamName: 'spartans',
    errorMonitor: true,
  };
  const experimentsConfig = {"scopes":["test"],"centralized":true};
  const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/c17fdbb6a863fb49/packages/rentle-secured-checkout-app/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};
  const defaultTranslations = {"app.widget.message":"Hello, this is a widget!","settings.tab.title":"Wix Secured Checkout","settings.tab.content":"Explanation why the widget is needed"};
  const createFlowAPI = createFlowAPIFabric({
    sentryConfig,
    experimentsConfig,
    translationsConfig,
    defaultTranslations,
    biLogger,
    shouldUseEssentials: true,
    artifactId: 'rentle-secured-checkout-app',
    appDefId: '375361af-6833-48ea-97d4-c07812ca06de',
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: 'assets/locales',
  });

  const useAPIsFromExports = typeof editorScriptEntry.exports === "function";
  if (editorReady) {
    editorReady = editorReadyWrapper({
      editorReady,
      createFlowAPI,
      shouldSkipAPIOverrides: useAPIsFromExports,
    });
    if (false) {
      const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
      editorReady = hot(module, editorReady)
    }
  }
  export { editorReady };
  export { onEvent };
  export const exports = useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports;
  export const getAppManifest = editorScriptEntry.getAppManifest ? getAppManifestWrapper(editorScriptEntry.getAppManifest, createFlowAPI) : undefined;
  export const handleAction = editorScriptEntry.handleAction;
  
